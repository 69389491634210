import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { setDiscordState, setInfoBannerMessage } from 'src/common/actions/app';
import { loginWithOauth } from 'src/common/actions/auth';
import { openNowPlayingDialog } from 'src/common/actions/dialog';
import { logClientError, logClientInfo } from 'src/common/actions/logging';
import { showMessage } from 'src/common/actions/messagePopover';
import {
  pause,
  play,
  seek,
  stop,
  tuneWithGuideId,
} from 'src/common/actions/player';
import { changePlaybackRateIfPossible } from 'src/common/actions/tuner';
import {
  DISCORD_DEBUG_MODE_ENABLED,
  DISCORD_MAX_PARTY_SIZE,
} from 'src/common/constants/experiments/discord';
import {
  DISCORD_RICH_PRESENCE_DETAILS_BROWSING,
  DISCORD_RICH_PRESENCE_DETAILS_PLAYING,
} from 'src/common/constants/localizations/discord';
import { HOME_BROWSE_PATH } from 'src/common/constants/paths';
import { playerStatuses } from 'src/common/constants/playerStatuses';
import { manualDiscordModes } from 'src/common/constants/queryParams';
import useActions from 'src/common/hooks/useActions';
import { useCustomHistory } from 'src/common/hooks/useCustomHistory';
import useExperiment from 'src/common/hooks/useExperiment';
import useOnMount from 'src/common/hooks/useOnMount';
import usePrevious from 'src/common/hooks/usePrevious';
import { LocationAndLocalizationContext } from 'src/common/providers/LocationAndLocalizationProvider';
import {
  selectBreakpoint,
  selectIsDiscord,
  selectIsFord,
  selectManualDiscordMode,
  selectTuneInUserSerial,
} from 'src/common/selectors/app';
import {
  selectIsTunerReady,
  selectNowPlaying,
  selectNowPlayingRejectReasonKey,
  selectPlaybackRate,
  selectPlayerStatus,
  selectPositionInfo,
  selectTunedGuideId,
  selectUserInitiatedSeekCount,
} from 'src/common/selectors/player';
import { isXXSmall } from 'src/common/utils/breakpoints';
import { useBrowsiesDefaultLandingPage } from '../../../../hooks/useBrowsiesDefaultLandingPage';
import { splashScreenTypes } from '../../constants';
import { useDiscordGeorestrictionsBanner } from '../useDiscordGeorestrictionsBanner';

const playerStatusEventTriggers = {
  [playerStatuses.connecting]: true,
  [playerStatuses.playing]: true,
  // these will all be treated as a "pause" event
  [playerStatuses.idle]: true,
  [playerStatuses.paused]: true,
  [playerStatuses.stopped]: true,
  [playerStatuses.popout]: true,
};
const actionsList = {
  logClientError,
  logClientInfo,
  setDiscordState,
  seek,
  pause,
  play,
  stop,
  tuneWithGuideId,
  changePlaybackRateIfPossible,
  loginWithOauth,
  openNowPlayingDialog,
  showMessage,
  setInfoBannerMessage,
};
const defaultHookReturnValue = {};

export function useDiscord(apiClient, history, routeProps) {
  const isDiscord = useSelector(selectIsDiscord);
  const isFord = useSelector(selectIsFord);

  // isDiscord is static for the life of an app session, so it's ok to break React's Rules of Hooks here
  if (!isDiscord) {
    return defaultHookReturnValue;
  }

  // isFord is static for the life of an app session, so it's ok to break React's Rules of Hooks here
  if (isFord) {
    if (routeProps.isLanding) {
      history.replace(HOME_BROWSE_PATH);

      return {
        isDiscordReady: true,
        showDiscordSplashScreen: routeProps.isLanding,
      };
    }

    return defaultHookReturnValue;
  }

  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const manualDiscordMode = useSelector(selectManualDiscordMode);
  const isUiOnlyDiscordMode = manualDiscordMode === manualDiscordModes.ui;
  const defaultLandingPage =
    // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
    useBrowsiesDefaultLandingPage() || HOME_BROWSE_PATH;
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const actions = useActions(actionsList);

  // Discord UI mode is static for the life of an app session, so it's ok to break React's Rules of Hooks here
  if (isUiOnlyDiscordMode) {
    // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
    useOnMount(() => {
      async function initUiMode() {
        const { getMockDiscordState } = await import('../../discordManager');

        actions.setDiscordState(getMockDiscordState());

        if (routeProps.isLanding) {
          history.replace(defaultLandingPage);
        }
      }

      initUiMode();
    });

    return {
      isDiscordReady: true,
      showDiscordSplashScreen: routeProps.isLanding,
    };
  }

  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const isDebugModeEnabled = useExperiment(DISCORD_DEBUG_MODE_ENABLED);
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const discordManagerRef = useRef();
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const positionInfo = useSelector(selectPositionInfo);
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const userInitiatedSeekCount = useSelector(selectUserInitiatedSeekCount);
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const playerStatus = useSelector(selectPlayerStatus);
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const previousPlayerStatus = usePrevious(playerStatus);
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const tunedGuideId = useSelector(selectTunedGuideId);
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const playbackRate = useSelector(selectPlaybackRate);
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const isTunerReady = useSelector(selectIsTunerReady);
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const nowPlaying = useSelector(selectNowPlaying);
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const maxPartySize = useExperiment(DISCORD_MAX_PARTY_SIZE);
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const tuneInUserSerial = useSelector(selectTuneInUserSerial);
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const breakpoint = useSelector(selectBreakpoint);
  const hasNowPlayingRejectReason = Boolean(
    // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
    useSelector(selectNowPlayingRejectReasonKey),
  );
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const stateRef = useRef();
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const [isDiscordReady, setIsDiscordReady] = useState(false);
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  const [splashScreenType, setSplashScreenType] = useState(null);

  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  useOnMount(() => {
    let teardown;

    async function loadDiscordManager() {
      const { discordManager } = await import('../../discordManager');

      discordManagerRef.current = discordManager;
      teardown = discordManager.destroy;

      discordManager.on('authed', () => {
        history.replace(defaultLandingPage);
        discordManager.setRichPresence(
          getLocalizedText(DISCORD_RICH_PRESENCE_DETAILS_BROWSING),
        );
      });
      discordManager.on('ready', () => setIsDiscordReady(true));
      discordManager.on('maxParticipantsReached', () => {
        setSplashScreenType(splashScreenTypes.maxParticipants);
      });

      await discordManager.init({
        apiClient,
        actions,
        stateRef,
        isDebugModeEnabled,
      });

      return () => {
        teardown?.();
      };
    }

    function preventRightClick(e) {
      e.preventDefault();
    }

    loadDiscordManager();
    document.addEventListener('contextmenu', preventRightClick);

    return () => {
      document.removeEventListener('contextmenu', preventRightClick);
      teardown?.();
    };
  });

  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  useCustomHistory();
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  useDiscordGeorestrictionsBanner();

  // Storing state changes in a ref to avoid unnecessary re-renders
  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  useEffect(() => {
    stateRef.current = {
      positionInfo,
      isTunerReady,
      playerStatus,
      maxPartySize,
      serial: tuneInUserSerial,
    };
  }, [
    positionInfo,
    maxPartySize,
    isTunerReady,
    playerStatus,
    tuneInUserSerial,
  ]);

  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  useEffect(() => {
    if (
      discordManagerRef.current?.canControlPlayback &&
      userInitiatedSeekCount
    ) {
      discordManagerRef.current.setListeningPosition();
    }
  }, [userInitiatedSeekCount]);

  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  useEffect(() => {
    if (
      discordManagerRef.current?.canControlPlayback &&
      playerStatusEventTriggers[playerStatus] &&
      previousPlayerStatus !== playerStatuses.failed &&
      !hasNowPlayingRejectReason
    ) {
      discordManagerRef.current.setListening({
        guideId: tunedGuideId,
        isPlaying:
          playerStatus === playerStatuses.connecting ||
          playerStatus === playerStatuses.playing,
        playbackRate,
      });
    }
  }, [
    playerStatus,
    previousPlayerStatus,
    tunedGuideId,
    playbackRate,
    hasNowPlayingRejectReason,
  ]);

  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  useEffect(() => {
    if (splashScreenType === splashScreenTypes.maxParticipants) {
      return;
    }

    if (isXXSmall(breakpoint)) {
      setSplashScreenType(null);
      return;
    }

    if (tunedGuideId) {
      actions.openNowPlayingDialog();
      return;
    }

    setSplashScreenType(splashScreenTypes.pictureInPicture);
  }, [actions, breakpoint, splashScreenType, tunedGuideId]);

  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  useEffect(() => {
    if (nowPlaying?.title) {
      discordManagerRef.current?.setRichPresence(
        getLocalizedText(DISCORD_RICH_PRESENCE_DETAILS_PLAYING),
        nowPlaying?.title,
      );
    }
  }, [getLocalizedText, nowPlaying?.title]);

  // biome-ignore lint/correctness/useHookAtTopLevel: to be replaced with file- or block-scoped disable comments when biome supports it
  return useMemo(
    () => ({
      isDiscordReady,
      showDiscordSplashScreen:
        routeProps.isLanding || !isDiscordReady || splashScreenType,
      splashScreenType,
    }),
    [isDiscordReady, splashScreenType, routeProps.isLanding],
  );
}
